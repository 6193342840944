import { AlertColor, AlertPropsColorOverrides } from "@mui/material"
import { OverridableStringUnion } from "@mui/types"
import { create } from "zustand"

type AlertSeverity = OverridableStringUnion<AlertColor, AlertPropsColorOverrides>

type SnackbarMessage = {
  id: number
  message: string
  severity: AlertSeverity
}

type Store = {
  messages: SnackbarMessage[]
  maxStack: number

  successMessage: (message: string) => void
  errorMessage: (message: string) => void
  infoMessage: (message: string) => void
  warningMessage: (message: string) => void

  removeMessage: (id: number) => void
}

export const useMessageSnackbarStore = create<Store>((set) => ({
  messages: [],
  maxStack: 5,

  successMessage: (message: string) =>
    set((state) => ({
      messages: [
        ...state.messages.slice(-state.maxStack),
        { id: Date.now(), message, severity: "success" },
      ],
    })),

  errorMessage: (message: string) =>
    set((state) => ({
      messages: [
        ...state.messages.slice(-state.maxStack),
        { id: Date.now(), message, severity: "error" },
      ],
    })),

  infoMessage: (message: string) =>
    set((state) => ({
      messages: [
        ...state.messages.slice(-state.maxStack),
        { id: Date.now(), message, severity: "info" },
      ],
    })),

  warningMessage: (message: string) =>
    set((state) => ({
      messages: [
        ...state.messages.slice(-state.maxStack),
        { id: Date.now(), message, severity: "warning" },
      ],
    })),

  removeMessage: (id: number) =>
    set((state) => ({
      messages: state.messages.filter((message) => message.id !== id),
    })),
}))
