import { refreshToken } from "@/services/login/refreshToken"
import { useEffect, useMemo, useState } from "react"

export default function useNotificationWebSocket(userId: string) {
  const [message, setMessage] = useState<string | null>(null)
  const [count, setCount] = useState<number>(0)
  const [isConnected, setIsConnected] = useState(false)
  const [isError, setIsError] = useState<Event | null>(null)
  
  const websocketPath = useMemo(() => {
  if (!process.env.NEXT_PUBLIC_BASE_URL) return "";

    return process.env.NEXT_PUBLIC_BASE_URL.replace(/^http/, "ws") + 
          (process.env.NEXT_PUBLIC_AUTH_SERVICE_PATH || "");
  }, []);

  useEffect(() => {
    if (!userId) return

    const socket = new WebSocket(`${websocketPath}/ws/notifications/${userId}`)

    socket.onopen = () => {
      setIsConnected(true)
    }

    socket.onmessage = (event) => {
      setCount(Math.random())
      setMessage(event.data)
      refreshToken()
    }

    socket.onerror = (error) => {
      setIsError(error)
    }

    socket.onclose = () => {
      setIsConnected(false)
    }

    return () => {
      socket.close()
    }
  }, [userId, websocketPath])

  return {
    message,
    count,
    isConnected,
    isError,
  }
}
